import React from "react"
import { Link } from "gatsby"

export default function AboutSubNav(props){
    return(
        <nav className={props.heroBanner? "subnav_about hero" : "subnav_about"}>
            <ul>
                <li>
                    <Link to="/about/" activeClassName="active" partiallyActive={props.heroBanner} >About</Link>
                </li>
                <li>
                    <Link to="/about/colophon" activeClassName="active" partiallyActive={true} >Colophon</Link>
                </li>
                <li>
                    <Link to="/about/styleguide" activeClassName="active" partiallyActive={true} >Styleguide</Link>
                </li>
            </ul>
        </nav>
    )
}